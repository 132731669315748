const domReadyStates = ["complete", "interactive"];
function whenDomReady() {
  return new Promise((resolve) => {
    const { readyState } = window.document;
    if (domReadyStates.includes(readyState)) {
      resolve();
    } else {
      document.addEventListener("DOMContentLoaded", () => resolve());
    }
  });
}
export {
  whenDomReady
};
